/* The following block can be included in a custom.scss */

@import "~bootstrap/scss/functions"; 
@import "~bootstrap/scss/variables";

/* make the customizations */
$header-bg-color: #4b9434; // set the $primary variable
$success-color: #9b3f9c;
$header-color: white; // set the $primary variable

// merge with existing $theme-colors map
$theme-colors: map-merge($theme-colors, (
        "primary": $header-bg-color,
        "success": $success-color
));

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

.productlist .product img {
    height: 20rem;
    width: auto;
}

.navbar {
    .nav-link, .navbar-brand, p {
        color: $header-color;
    }
    .nav-link.active, .nav-link.show, .nav-link.hover {
        color: $header-color;
        text-decoration: underline;
    }
}